import React from "react";
import { Routes, Route } from "react-router-dom";

import IndexPage from "./pages/Index.js";
import _404Page from "./pages/404.js";
import { Terms } from "./components/terms/terms.js";
import { Privacy } from "./components/terms/privacy.js";
import { Contact } from "./components/terms/contact.js";
import Pricing from "./pages/Pricing.js";

function App() {
	return (
		<Routes>
			<Route
				path="/"
				element={<IndexPage />}
			/>
			<Route
				path="/pricing"
				element={<Pricing />}
			/>
			<Route
				path="/404"
				element={<_404Page />}
			/>
			<Route
				path="/terms"
				element={<Terms />}
			/>
			<Route
				path="/privacy"
				element={<Privacy />}
			/>
			<Route
				path="/contact"
				element={<Contact />}
			/>
		</Routes>
	);
}

export default App;
