import React from "react";

export default function PricingSectionPackaged() {
	return (
		<React.Fragment>
			<>
				<section
					className="py-20 xl:py-24 bg-white"
					style={{ backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")', backgroundPosition: "center" }}
				>
					<div className="container px-4 mx-auto">
						<div className="text-center">
							<h3 className="mb-6 text-3xl md:text-5xl text-coolGray-900 font-bold tracking-tighter">Simple Pricing Without Hidden Costs</h3>
						</div>
						<div className="flex flex-wrap justify-center -mx-4">
							<div className="w-full md:w-1/2 lg:w-1/3 p-4">
								<div className="flex flex-col pt-8 pb-8 h-full bg-coolGray-50 rounded-md shadow-md hover:scale-105 transition duration-500">
									<div className="px-8 text-center">
										<h3 className="mb-2 text-3xl md:text-4xl text-coolGray-800 font-semibold tracking-tighter">10 Credits</h3>
										<p className="mb-6 text-coolGray-400 font-medium">For 10 Infographics</p>
										<div className="mb-6">
											<span className="relative -top-10 right-1 text-3xl text-coolGray-900 font-bold">$</span>
											<span className="text-6xl md:text-7xl text-coolGray-900 font-semibold tracking-tighter">5</span>
										</div>
										<a
											className="inline-block py-4 px-7 mb-8 w-full text-base md:text-lg leading-6 text-green-50 font-medium text-center bg-green-500 hover:bg-green-600 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 rounded-md shadow-sm"
											href="/login"
										>
											Buy Now
										</a>
									</div>
									<ul className="self-start px-8">
										<li className="flex items-center mb-4 text-coolGray-500 font-medium">
											<img
												className="mr-3"
												src="flex-ui-assets/elements/pricing/checkbox-green.svg"
											/>
											<span>10 AI-Generated Infographic Designs</span>
										</li>
										<li className="flex items-center mb-4 text-coolGray-500 font-medium">
											<img
												className="mr-3"
												src="flex-ui-assets/elements/pricing/checkbox-green.svg"
											/>
											<span>Access to all templates</span>
										</li>
										<li className="flex items-center mb-4 text-coolGray-500 font-medium">
											<img
												className="mr-3"
												src="flex-ui-assets/elements/pricing/checkbox-green.svg"
											/>
											<span>Free Editor</span>
										</li>
										<li className="flex items-center text-coolGray-500 font-medium">
											<img
												className="mr-3"
												src="flex-ui-assets/elements/pricing/checkbox-green.svg"
											/>
											<span>Email Support</span>
										</li>
									</ul>
								</div>
							</div>
							<div className="w-full md:w-1/2 lg:w-1/3 p-4">
								<div className="flex flex-col pt-8 pb-8 h-full bg-coolGray-50 rounded-md shadow-md hover:scale-105 transition duration-500">
									<div className="px-8 text-center">
										<h3 className="mb-2 text-3xl md:text-4xl text-coolGray-800 font-semibold tracking-tighter">30 Credits</h3>
										<p className="mb-6 text-coolGray-400 font-medium">For 30 Infographics</p>
										<div className="mb-6">
											<span className="relative -top-10 right-1 text-3xl text-coolGray-900 font-bold">$</span>
											<span className="text-6xl md:text-7xl text-coolGray-900 font-semibold tracking-tighter">13</span>
										</div>
										<a
											className="inline-block py-4 px-7 mb-8 w-full text-base md:text-lg leading-6 text-green-50 font-medium text-center bg-green-500 hover:bg-green-600 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 rounded-md shadow-sm"
											href="/login"
										>
											Buy Now
										</a>
									</div>
									<ul className="self-start px-8">
										<li className="flex items-center mb-4 text-coolGray-500 font-medium">
											<img
												className="mr-3"
												src="flex-ui-assets/elements/pricing/checkbox-green.svg"
											/>
											<span>30 AI-Generated Infographic Designs</span>
										</li>
										<li className="flex items-center mb-4 text-coolGray-500 font-medium">
											<img
												className="mr-3"
												src="flex-ui-assets/elements/pricing/checkbox-green.svg"
											/>
											<span>Access to all templates</span>
										</li>
										<li className="flex items-center mb-4 text-coolGray-500 font-medium">
											<img
												className="mr-3"
												src="flex-ui-assets/elements/pricing/checkbox-green.svg"
											/>
											<span>Free Editor</span>
										</li>
										<li className="flex items-center text-coolGray-500 font-medium">
											<img
												className="mr-3"
												src="flex-ui-assets/elements/pricing/checkbox-green.svg"
											/>
											<span>Email Support</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		</React.Fragment>
	);
}
